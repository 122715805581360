/**
 * TilePlus Values
 *
 * 1st file in project’s cascading logic.
 * This is @imported within Vuetify (.scss and .js)
 * and in `wx_variables.scss`.
 */

// Vuetify’s Material Themes available as props on components
$material-darkTheme-vuetifyPropOptions: (
  "primary": hsl(178, 82%, 38%),
  "secondary": hsl(219, 20%, 31%),
  "neutral": hsl(218, 26%, 26%),
  "accent": hsl(21, 84%, 54%),
  "info": hsl(216, 55%, 52%),
  "success": hsl(178, 82%, 38%),
  "warning": hsl(44, 97%, 45%),
  "error": hsl(352, 80%, 56%) /* worximity_logo #e9344d */,
);
$material-lightTheme-vuetifyPropOptions: (
  "primary": hsl(177, 100%, 39%),
  "secondary": hsl(206, 30%, 85%),
  "neutral": hsl(206, 39%, 93%),
  "accent": hsl(21, 87%, 49%) /* official #ea5c10 */,
  "info": hsl(216, 60%, 55%),
  "success": hsl(177, 100%, 39%),
  "warning": hsl(44, 97%, 55%),
  "error": hsl(352, 100%, 61%),
);

/**
 * Unthemed Colors
 */
$wx-unthemed-colorValues: (
  "blackText": hsla(220, 15%, 8%, 0.92),
  "grayBorder": hsla(216, 27%, 63%, 0.3),
  "lightGrayBackground": hsl(216, 27%, 96%),
  "overlayBackground": hsl(214, 88%, 3%) /* Vuetify prop `overlay-color` */,
  "redHighlight": hsl(0, 100%, 48%) /* t+ Dashboard chart’s OEE target */,
);
// TilePlus Smart Factory Colors
$wxSmartFactory-colorValues: (
  "sf1": #b4c912,
  "sf2": #ff6700,
  "sf3": #12b2ac,
  "sf4": #fcc01c,
);
// TilePlus Production Unit State Colors
$wxProductionState-colorValues: (
  "uptime": #12b2ac,
  "plannedDowntime": #296664,
  "unjustifiedDowntime": #ff3952,
  "justifiedDowntime": #9c0015,
  "unjustifiedLowThroughput": #ff6700,
  "justifiedLowThroughput": #cc8352,
  "disconnected": #fcc01c,
  "outOfProduction": #243347,
  "unknown": #03132a,
  // OBSOLETE VARIABLES: Hyphen nomenclature is not suported in JS
    "planned-downtime": #296664,
  "unjustified-downtime": #ff3952,
  "justified-downtime": #9c0015,
  "unjustified-low-throughput": #ff6700,
  "justified-low-throughput": #cc8352,
  "out-of-production": #243347,
);

/**
 * Matching Tone Pigments
 * `https://worximity.atlassian.net/l/c/H1RkjF3M`
 *
 * DO NOT use kebab-case (`-` dash character) is NOT SUPPORTED 
 * in javascript (for chartJS API). Use chamelCase or snake_case 
 * (`_` underscores) are supported.
 */
$wxDatas-darkTheme-colorValues: (
  /* Dark Backgrounds */ "wxData_bgColor": hsl(219, 20%, 40%) /* default/Null/NaN = secondary */,
  "wxData_bgColor1": hsl(240, 35%, 35%),
  "wxData_bgColor2": hsl(38, 20%, 35%),
  "wxData_bgColor3": hsl(175, 27%, 30%),
  "wxData_bgColor4": hsl(310, 27%, 33%),
  "wxData_bgColor5": hsl(230, 25%, 40%),
  "wxData_bgColor6": hsl(70, 30%, 29%),
  "wxData_bgColor7": hsl(195, 20%, 40%),
  "wxData_bgColor8": hsl(260, 30%, 36%),
  "wxData_bgColor9": hsl(24, 8%, 30%),
  /* Dark Lines */ "wxData_lineColor": hsl(216, 21%, 36%) /* default/Null/NaN = --color-control-stroke */,
  "wxData_lineColor1": hsl(240, 35%, 70%),
  "wxData_lineColor2": hsl(38, 20%, 60%),
  "wxData_lineColor3": hsl(175, 29%, 60%),
  "wxData_lineColor4": hsl(310, 27%, 60%),
  "wxData_lineColor5": hsl(230, 25%, 65%),
  "wxData_lineColor6": hsl(70, 30%, 60%),
  "wxData_lineColor7": hsl(195, 20%, 70%),
  "wxData_lineColor8": hsl(260, 30%, 65%),
  "wxData_lineColor9": hsl(24, 8%, 60%)
);
$wxDatas-lightTheme-colorValues: (
  /* Light Backgrounds */ "wxData_bgColor": hsl(206, 30%, 85%) /* default/Null/NaN = secondary */,
  "wxData_bgColor1": hsl(240, 40%, 83%),
  "wxData_bgColor2": hsl(38, 48%, 78%),
  "wxData_bgColor3": hsl(175, 22%, 77%),
  "wxData_bgColor4": hsl(310, 42%, 80%),
  "wxData_bgColor5": hsl(230, 37%, 84%),
  "wxData_bgColor6": hsl(70, 29%, 75%),
  "wxData_bgColor7": hsl(195, 30%, 75%),
  "wxData_bgColor8": hsl(260, 54%, 80%),
  "wxData_bgColor9": hsl(24, 15%, 70%),
  /* Light Lines */ "wxData_lineColor": hsl(216, 29%, 66%) /* default/Null/NaN = --color-control-stroke */,
  "wxData_lineColor1": hsl(240, 40%, 50%),
  "wxData_lineColor2": hsl(38, 48%, 45%),
  "wxData_lineColor3": hsl(175, 20%, 35%),
  "wxData_lineColor4": hsl(310, 42%, 40%),
  "wxData_lineColor5": hsl(230, 37%, 45%),
  "wxData_lineColor6": hsl(70, 29%, 40%),
  "wxData_lineColor7": hsl(195, 30%, 45%),
  "wxData_lineColor8": hsl(260, 60%, 55%),
  "wxData_lineColor9": hsl(24, 15%, 40%)
);

/** 
 * Wx Colors exported to JavaScript for Charts
 * `https://worximity.atlassian.net/l/c/H1RkjF3M`
 *
 * DO NOT use kebab-case (`-` dash character) is NOT SUPPORTED 
 * in javascript (for chartJS API). Use chamelCase or snake_case 
 * (`_` underscores) are supported.
 */
$wxJavascript-darkTheme-colorValues: (
  "base_theme": hsl(215, 87%, 9%) /* var(--color-base-theme) */,
  "baseBackground_theme": hsl(217, 30%, 25%) /* var(--color-base-background) */,
  "border_theme": hsla(215, 50%, 80%, 0.2) /* var(--color-border-theme) */,
  "control_theme": hsla(215, 30%, 70%, 0.15) /* var(--color-control-theme) */,
  "gridRuler_theme": hsla(215, 60%, 90%, 0.15) /* charts data-grid */,
  "text_theme": hsl(0, 0%, 100%) /* var(--color-text-theme) */,
  "textDisabled_theme": hsla(0, 0%, 100%, 0.3) /* var(--color-disabled-theme) */,
  "textSubtle_theme": hsla(0, 0%, 100%, 0.7) /* var(--color-text-subtle-theme) */,
);
$wxJavascript-lightTheme-colorValues: (
  "base_theme": hsl(204, 45%, 98%),
  "baseBackground_theme": hsl(0, 0%, 100%),
  "border_theme": rgba(135, 156, 186, 0.3),
  "control_theme": hsla(215, 30%, 30%, 0.1),
  "gridRuler_theme": hsla(215, 30%, 70%, 0.3),
  "text_theme": hsla(0, 0%, 0%, 0.87),
  "textDisabled_theme": hsla(0, 0%, 0%, 0.26),
  "textSubtle_theme": hsla(0, 0%, 0%, 0.6),
);

/**
 * Responsive Grid Breakpoints
 * `xs` = Any size less than `smBreakpoint`
 */
$wxBreakpoint-pixelValues: (
  "smBreakpoint": 600px,
  "mdBreakpoint": 960px,
  "lgBreakpoint": 1264px,
  "xlBreakpoint": 1904px,
);

/** - - - - - - - - - - - - - - - - - - - -
 * Export Sass variables to JavaScript
 *
 * The following :export{} function is to make the 
 * above values available from within JS files. 
 * So no values from this Sass document are duplicated 
 * in our project making it THE SOURCE OF TRUTH to 
 * import where needed.
 * For example, the `./vuetify.js` config file.
 *
 * `unquote($key)` is required to stripe the 
 * quotes from Sass maps for JavaScript.
 * Otherwise they are treated as strings once 
 * imported within JS.
 */
:export {
  // Quantity of color variables to loop within wxDatas (excluding null/NaN)
  #{wxData_colorsCount}: 9;

  // Unthemed color export
  @each $key, $varValue in $wxSmartFactory-colorValues {
    color_#{unquote($key)}: #{$varValue};
  }
  @each $key, $varValue in $wxProductionState-colorValues {
    color_#{unquote($key)}: #{$varValue};
  }
  @each $key, $varValue in $wx-unthemed-colorValues {
    color_#{unquote($key)}: #{$varValue};
  }

  // DarkMode theme exports
  @each $key, $varValue in $material-darkTheme-vuetifyPropOptions {
    #{unquote($key)}Dark: #{$varValue};
  }
  @each $key, $varValue in $wxDatas-darkTheme-colorValues {
    #{unquote($key)}Dark: #{$varValue};
  }
  @each $key, $varValue in $wxJavascript-darkTheme-colorValues {
    color_#{unquote($key)}Dark: #{$varValue};
  }

  // LightMode theme exports
  @each $key, $varValue in $material-lightTheme-vuetifyPropOptions {
    #{unquote($key)}Light: #{$varValue};
  }
  @each $key, $varValue in $wxDatas-lightTheme-colorValues {
    #{unquote($key)}Light: #{$varValue};
  }
  @each $key, $varValue in $wxJavascript-lightTheme-colorValues {
    color_#{unquote($key)}Light: #{$varValue};
  }

  /**
   * Unpixelate Pixel Values
   * divide by 1px to get a unitless value (strip the `px` suffix) for functions.
   * ex: 600px / 1px = 600
   */
  @each $key, $pxValue in $wxBreakpoint-pixelValues {
    #{unquote($key)}: ($pxValue / 1px);
  }
}
